import type { UserData } from 'types/userData'
import { SET_USER_DATA_AKAMAI_ENDPOINT } from 'user-data/constants'
import { env } from 'utils/envManager'

export const setUserDataCookies = async ({
	userCountryISO,
	userLanguageISO,
	userBrandID,
}: UserData): Promise<void> => {
	const url = new URL(
		SET_USER_DATA_AKAMAI_ENDPOINT,
		env.NEXT_PUBLIC_SITE_BASE_URL
	)

	if (userCountryISO) {
		url.searchParams.set('countryISO', userCountryISO.toLowerCase())
	}
	if (userLanguageISO) {
		url.searchParams.set('languageISO', userLanguageISO)
	}
	if (userBrandID) {
		url.searchParams.set('brandID', userBrandID)
	}
	try {
		await fetch(url.href, { next: { revalidate: 0 } })
	} catch (e) {
		console.error('Error setting user data cookies')
	}
}
