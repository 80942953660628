import type { UserData } from 'types/userData'

import { getUserDataCookies } from './utils/cookies/getUserDataCookies'
import { getUserDataLocalStorage } from './utils/localStorage/getUserDataLocalStorage'

export const getUserData = async (): Promise<UserData | null> => {
	const userDataFromLocalStorage = getUserDataLocalStorage()

	if (userDataFromLocalStorage) {
		return userDataFromLocalStorage
	}

	const userDataFromCookies = await getUserDataCookies()

	if (userDataFromCookies) {
		return userDataFromCookies
	}

	return null
}
