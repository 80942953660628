import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { Domains } from 'monitoring/constants/domains'
import { useLog } from 'monitoring/sendLog/client/useLog'
import { useEffect } from 'react'
import { fetcher } from 'services/rest/fetcher'
import { useSession } from 'session/src/hooks/useSession'
import useSWRImmutable from 'swr/immutable'
import type { SegmentsResponse } from 'types/segmentation'
import { env } from 'utils/envManager'

import type { UserDataProviderProps } from '../context/UserDataProvider'

export function useHandleSegmentation(
	dispatch: (newState: Partial<UserDataProviderProps>) => void
) {
	const { externalId } = useSession()
	const { sendLog } = useLog()
	const { enabled: allowSegmentation } = useFeatureFlag(
		featureFlagKeys.PlpSegmentation
	)
	const { data, error } = useSWRImmutable<SegmentsResponse | null>(
		allowSegmentation && externalId
			? new URL(
					`${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-product-lists/v1/channels/shop/customers/${externalId}/segments`
				).href
			: null,
		fetcher,
		{}
	)

	useEffect(() => {
		if (error) {
			dispatch({ segmentation: undefined })
			sendLog({
				domain: Domains.PLP,
				message: `Error fetching segmentation: ${error}`,
			})
		} else if (data) {
			dispatch({ segmentation: data.segments })
		} else {
			dispatch({ segmentation: undefined })
		}
	}, [data, error])

	return null
}
