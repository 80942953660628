'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useState } from 'react'

const jsSrc = process.env.NEXT_PUBLIC_DYNATRACE_JS

export function Dynatrace(): React.ReactNode {
	const { cookiesConsent } = useCookiesContext()
	const [loaded, setLoaded] = useState(false)
	const { pageType } = useMasterData()

	useEffect(() => {
		if (jsSrc && typeof cookiesConsent === 'boolean' && !loaded) {
			const script = document.createElement('script')
			script.src = jsSrc
			script.async = true
			script.crossOrigin = 'anonymous'
			script.onload = () => {
				setLoaded(true)
			}

			document.body.appendChild(script)
		}
	}, [cookiesConsent])

	useEffect(() => {
		if (jsSrc && typeof cookiesConsent === 'boolean' && loaded) {
			if (cookiesConsent) {
				window.dtrum.enable()
				window.dtrum.enableManualPageDetection()
				window.dtrum.setPage({
					name: window.location.pathname + window.location.search,
					group: pageType,
				})
			} else {
				window.dtrum.disable()
			}
		}
	}, [cookiesConsent, loaded])

	return null
}
