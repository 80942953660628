'use client'

import { useEffect } from 'react'
import { Keyboard } from 'types/keyboard'

// k from 'Keyboard'. This class is used to show the outline when the user is using the keyboard
// and focus-visible is not enough to make UX and product team happy
const KEYBOARD_CLASS = 'k'

export const OutlineWrapper = ({ children }: { children: React.ReactNode }) => {
	useEffect(() => {
		const onClick = () => {
			document.body.classList.remove(KEYBOARD_CLASS)
		}

		const onKeyDown = (e: KeyboardEvent) => {
			if (
				e.key === Keyboard.Tab ||
				e.key === Keyboard.Enter ||
				e.key === Keyboard.Space
			) {
				document.body.classList.add(KEYBOARD_CLASS)
			}
		}

		document.addEventListener('keydown', onKeyDown)
		document.addEventListener('click', onClick)

		return () => {
			document.removeEventListener('click', onClick)
			document.removeEventListener('keydown', onKeyDown)
		}
	}, [])

	return children
}
