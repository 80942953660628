import { Events } from 'webview/constants/events'
import type { OptionalParams } from 'webview/types'

import { sendAppEvent } from './sendAppEvent'

export const NavigateToCommand = 'navigateTo'
export const NavigateToListener = 'navigator'

export function sendAppNavigateTo<T>(
	event: Events,
	optionalParams?: OptionalParams<T>
) {
	sendAppEvent({
		command: `${NavigateToCommand}${event}`,
		listener: NavigateToListener,
		optionalParams,
	})
}
