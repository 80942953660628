import type { UserData, UserDataStorage } from 'types/userData'
import { GET_USER_DATA_AKAMAI_ENDPOINT } from 'user-data/constants'
import { env } from 'utils/envManager'

export const getUserDataCookies = async (): Promise<UserData | null> => {
	const url = new URL(
		GET_USER_DATA_AKAMAI_ENDPOINT,
		env.NEXT_PUBLIC_SITE_BASE_URL
	)
	try {
		const response = await fetch(url.href, {
			next: { revalidate: 0 },
		})
		if (response.ok) {
			const json: UserDataStorage = await response.json()
			const { countryISO, languageISO, brandID } = json
			return {
				userCountryISO: countryISO,
				userLanguageISO: languageISO,
				userBrandID: brandID,
			}
		}

		return null
	} catch (error) {
		console.error('Error fetching user data from Akamai: ', error)
		return null
	}
}
