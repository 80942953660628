'use client'

import { env } from 'utils/envManager'

interface DeviceDataResponse {
	country: string
	region: string
	lat: number
	lng: number
	browser: string
}

export const getDeviceData = async (): Promise<DeviceDataResponse> => {
	const response = await fetch(
		new URL(`${env.NEXT_PUBLIC_SITE_BASE_URL}/device-data`).href,
		{
			next: { revalidate: 0 },
		}
	)

	return response.json()
}
