'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { fetcher } from 'services/rest/fetcher'
import { useSession } from 'session/src/hooks/useSession'
import useSWRImmutable from 'swr/immutable'
import type { Datafile } from 'types/featureFlag'
import { env } from 'utils/envManager'
import { isBot } from 'utils/isBot/IsBot'

const initialState = {
	abTestReady: false,
	abRequested: false,
}

export const AbTestStateContext = createContext(initialState)
export const AbTestDispatchContext = createContext<
	Dispatch<SetStateAction<boolean>>
>(() => {
	/* do nothing */
})

type CreateUserContextFn =
	typeof import('ab-test/utils/userContext').createUserContext

let createUserContextFn: CreateUserContextFn | undefined

export const AbTestProvider = ({ children }: { children: React.ReactNode }) => {
	const masterdata = useMasterData()
	const { isQa } = masterdata

	const session = useSession()
	const { isReady } = session

	const [abTestReady, setAbTestReady] = useState(false)
	const [abRequested, setAbRequested] = useState(false)
	const [isSdkReady, setIsSdkReady] = useState(false)

	const bots = isBot() || isQa
	const shouldGetSdk = abRequested && isReady && !bots

	const { data: datafile } = useSWRImmutable<Datafile>(
		bots
			? null
			: `${env.NEXT_PUBLIC_FEATURE_FLAGS_SERVICE}/datafiles/${env.NEXT_PUBLIC_AB_TEST_OPTIMIZELY_SDK}.json`,
		fetcher
	)

	useEffect(() => {
		if (!isSdkReady && shouldGetSdk) {
			if (createUserContextFn) {
				setIsSdkReady(true)
			} else {
				const getSdk = async (): Promise<void> => {
					createUserContextFn = (await import('../utils/userContext'))
						.createUserContext
					setIsSdkReady(true)
				}

				getSdk()
			}
		}
	}, [isSdkReady, shouldGetSdk, datafile])

	useEffect(() => {
		if (datafile && isSdkReady && !abTestReady) {
			;(createUserContextFn as CreateUserContextFn)({
				datafile,
				masterdata,
				session,
			})

			setAbTestReady(true)
		}
	}, [datafile, isSdkReady, abTestReady])

	const value = useMemo(
		() => ({ abTestReady, abRequested }),
		[abTestReady, abRequested]
	)

	return (
		<AbTestStateContext.Provider value={value}>
			<AbTestDispatchContext.Provider value={setAbRequested}>
				{children}
			</AbTestDispatchContext.Provider>
		</AbTestStateContext.Provider>
	)
}
